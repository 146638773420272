import * as Sentry from '@sentry/react';
import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { type Role } from '@app/enums/role';
import { getDecodedIdTokenData } from '@app/lib/forgeRock/getDecodedIdTokenData';

interface AuthState {
  customerId: string;
  userId: string;
  personaId: string;
  roles: Role[];
  accountIndex: number;
  idToken?: string;
  accessToken?: string;
}

interface AuthActions {
  setUser: (idToken: string, accessToken: string) => void;
  reset: () => void;
}

const initialState: AuthState = {
  customerId: '',
  userId: '',
  personaId: '',
  roles: [],
  accountIndex: 0,
  idToken: undefined,
  accessToken: undefined,
};

export const useAuthStore = createWithEqualityFn<AuthState & AuthActions>()(
  persist(
    set => ({
      ...initialState,
      setUser: (idToken, accessToken) => {
        const { customerId, personaId, sub: userId, roles } = getDecodedIdTokenData(idToken);

        Sentry.setUser({ id: customerId });

        set({
          idToken,
          accessToken,
          customerId,
          personaId,
          userId,
          roles: roles.map(role => role.roleName),
        });
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => sessionStorage),
      partialize: state => ({ idToken: state.idToken, accessToken: state.accessToken }),
      version: 3,
    },
  ),
  shallow,
);
