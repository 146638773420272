import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import { Suspense, lazy, useEffect, type FC } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { AppSkeleton } from '@app/components/common/appSkeleton/appSkeleton';
import { appVersion } from '@app/configs/appVersion';
import { getExtraRequestHeaderParameters } from '@app/utils/getExtraRequestHeaderParameters';
import { handleGlobalAxiosError } from '@app/utils/handleGlobalAxiosError';
import { queryClient } from '@app/utils/queryClient';

const App = lazy(async () => import('@app/app'));

Sentry.init({
  enabled: process.env.SENTRY_DSN !== undefined && process.env.SENTRY_DSN.length > 0,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV,
  release: `netbank@${appVersion}`,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^\//, process.env.API_BASE_URL ?? ''],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

axios.interceptors.request.use(config => ({
  ...config,
  baseURL: process.env.API_BASE_URL,
  params: {
    ...(config.params as object | undefined),
    BXACC: process.env.API_BXACC,
  },
  headers: new axios.AxiosHeaders({
    ...getExtraRequestHeaderParameters(),
    ...config.headers,
  }),
}));

axios.interceptors.response.use(response => response, handleGlobalAxiosError);

const Root: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<AppSkeleton />}>
        <App />
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  root.render(<Root />);
}
