import { type FC, memo, useMemo } from 'react';

import binxLogo from '@app/assets/logo/binx_dark.svg';

const AppSkeletonComponent: FC = () => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-between">
      <div className="flex flex-1 items-center">
        <img alt="BinX Zrt." className="w-44 py-8" src={binxLogo} />
      </div>
      <p className="pb-6 text-xs text-gray-600">{`© ${currentYear} Binx Zrt.`}</p>
    </div>
  );
};

export const AppSkeleton = memo(AppSkeletonComponent);
