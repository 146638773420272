import { useAuthStore } from '@app/stores/useAuthStore';

export const getExtraRequestHeaderParameters = () => {
  const headers: Record<string, string> = {
    'X-Request-ID': crypto.randomUUID(),
  };

  const idToken = useAuthStore.getState().idToken;
  const accessToken = useAuthStore.getState().accessToken;

  if (idToken) {
    headers['X-baasflow-identity'] = idToken;
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return headers;
};
