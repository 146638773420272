import { type AxiosError } from 'axios';

import { useAuthStore } from '@app/stores/useAuthStore';

export const handleGlobalAxiosError = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    useAuthStore.getState().reset();

    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  }

  return Promise.reject(error);
};
