import { jwtDecode } from 'jwt-decode';

import { type Role } from '@app/enums/role';

export interface IdTokenData {
  at_hash: string;
  sub: string;
  auditTrackingId: string;
  roles: Array<{
    roleId: string;
    roleName: Role;
  }>;
  iss: string;
  tokenName: string;
  channel: string;
  sid: string;
  acr: string;
  azp: string;
  auth_time: number;
  customerId: string;
  exp: number;
  iat: number;
  subname: string;
  aud: string;
  customerShortName: string;
  name: string;
  realm: string;
  installationId: string;
  tokenType: string;
  personaId: string;
}

export const getDecodedIdTokenData = (token: string): IdTokenData => {
  return jwtDecode(token);
};
